import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/20/solid"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { HeadingOne } from "../components/Atoms"
import { Layout } from "../components/Layout"
import { Seo } from "../components/Seo"
import { useSiteMetadata } from "../hooks/use-site-metadata"

// Render the credits table if roles exist
const renderCredits = (roles) => {
  if (!roles) return null

  return (
    <table className="table-auto text-left">
      <tbody>
        {roles.map((role, index) => (
          <tr key={index}>
            <td className="max-w-[160px] pr-5 uppercase md:max-w-none">
              <span>{role.function}:</span>
            </td>
            <td className="font-light capitalize">{role?.name ?? "N/A"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const renderVideoStills = (stills) => {
  if (!(stills && stills.length > 0)) return null

  return stills.map((still, index) => (
    <GatsbyImage
      key={index}
      className="mb-4"
      image={still.image.childImageSharp.gatsbyImageData}
      alt={still?.alt ? still.alt : ""}
    />
  ))
}

const renderExtraVideos = (extraVimeo) => {
  if (!(extraVimeo && extraVimeo.length > 0)) return null

  return extraVimeo.map((video) => (
    <div key={video.vimeo}>
      <h2 className="my-4 text-center text-base font-bold uppercase">{video.title}</h2>
      <div className="relative h-0 overflow-hidden pb-[56.25%]">
        <div className="absolute top-0 left-0 h-full w-full">
          <iframe
            src={`https://player.vimeo.com/video/${video.vimeo}?dnt=1`}
            title={video.title}
            className="h-full w-full border-none"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  ))
}

export default function Project({ data, pageContext }) {
  const { previous, next } = pageContext
  const { frontmatter, html } = data.markdownRemark
  const {
    title,
    main: { vimeo, roles, extraVimeo },
    image,
    alt,
    stills
  } = frontmatter
  const imageHeading = getImage(image)

  return (
    <Layout>
      <main className="mx-auto w-full max-w-4xl pb-4 px-2">
        <HeadingOne title={title} />
        {vimeo && (
          <div className="relative h-0 overflow-hidden pb-[56.25%]">
            <div className="absolute top-0 left-0 h-full w-full">
              <iframe
                title={title}
                src={`https://player.vimeo.com/video/${vimeo}?dnt=1`}
                className="h-full w-full border-none"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
        {!vimeo && <GatsbyImage image={imageHeading} alt={alt ?? ""} loading="eager" />}
        <section className="py-5 font-normal ">{renderCredits(roles)}</section>
        <div className="mb-4" dangerouslySetInnerHTML={{ __html: html }} />

        {renderVideoStills(stills)}

        {renderExtraVideos(extraVimeo)}

        <hr className="my-4 border-black" />
        <div className="flex justify-between text-xs sm:text-sm">
          {previous && (
            <Link
              to={previous.fields.slug}
              className="relative mr-auto flex gap-2 items-center justify-center after:absolute after:left-0 after:-bottom-2 after:w-full after:origin-right after:scale-x-0 after:border-b-2 after:border-solid after:border-b-black after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65,0.05,0.36,1)] after:content-[''] hover:after:origin-left hover:after:scale-x-100"
            >
              <ChevronDoubleLeftIcon className="h-5 w-5 fill-black" />
              {previous.frontmatter.title}
            </Link>
          )}
          {next && (
            <Link
              to={next.fields.slug}
              className="relative text-end ml-auto flex gap-2 items-center justify-center after:absolute after:left-0 after:-bottom-2 after:w-full after:origin-right after:scale-x-0 after:border-b-2 after:border-solid after:border-b-black after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65,0.05,0.36,1)] after:content-[''] hover:after:origin-left hover:after:scale-x-100"
            >
              {next.frontmatter.title}
              <ChevronDoubleRightIcon className="h-5 w-5 fill-black" />
            </Link>
          )}
        </div>
      </main>
    </Layout>
  )
}

export const Head = ({ location, data }) => {
  const { title: defaultTitle, siteUrl } = useSiteMetadata()
  const { frontmatter } = data.markdownRemark
  const { pathname } = location

  const title = frontmatter.title
  const description = frontmatter.description
  const image = frontmatter.image.publicURL

  return (
    <Seo title={`${title} | ${defaultTitle}`} description={description} pathname={pathname} image={image}>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "CreativeWork",
          "url": `${siteUrl}${pathname}`,
          "name": title,
          "description": description,
          "headline": "Get in Touch with Kevin Kimman",
          "image": `${siteUrl}${image}`,
          "author": {
            "@type": "Person",
            "name": "Kevin Kimman",
            "url": "https://www.kevinkimman.com/"
          }
        })}
      </script>
    </Seo>
  )
}

export const pageQuery = graphql`
  query ProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 900, quality: 90)
          }
        }
        alt
        description
        main {
          title
          vimeo
          roles {
            function
            name
          }
          extraVimeo {
            vimeo
            title
          }
        }
        stills {
          image {
            childImageSharp {
              gatsbyImageData(width: 880, quality: 90)
            }
          }
        }
      }
      html
    }
  }
`
